import React from 'react'

const NavList = ({ ul_class, li_class, close_click }) => {
    return (
        <ul className={`${ul_class}`}>
            <li className={`${li_class}`}><a href="/" onClick={close_click ? close_click : close_click}>HOME</a></li>
            <li className={`${li_class}`}><a href="#about" onClick={close_click ? close_click : close_click}>ABOUT </a></li>
            <li className={`${li_class}`}><a href="#tarrif" onClick={close_click ? close_click : close_click}>  TARIFF</a></li>
            <li className={`${li_class}`}><a href="#service" onClick={close_click ? close_click : close_click}>  SERVICE</a></li>

<li className={`${li_class}`}><a href="#vehicles" onClick={close_click ? close_click : close_click}>  VEHICLES</a></li>
            {/* <li className={`${li_class}`}><a href="/cities" onClick={close_click ? close_click : close_click}>  CITIES</a></li> */}

            <li className={`${li_class}`}><a href="#contact_us" onClick={close_click ? close_click : close_click}>CONTACT</a></li>
          {/* <li ><a href="#book_taxi_form" className='px-4 py-2 text-white rounded-3xl th-btn header-call-btn'  onClick={close_click ? close_click : close_click}>Book Now</a></li> */}
        </ul>
    )
}

export default NavList