import React from 'react'
import about from "../assets/images/about.png";
import { FaCheck } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";


const About = () => {
    return (
        <div id='about'>
            <div className='container py-5 mt-0 mx-auto px-4 md:px-8 lg:px-16 md:mt-10'>
                   <div className='flex flex-col items-center gap-3'>
                    <h4 className="text-3xl font-bold">WELCOME TO <span className='text-[#1c1e32]'>ELITE DROP TAXI</span></h4>
                     <img src={about} className='w-full lg:w-1/2'/>
                     <p className='text-center opacity-70'>Welcome to Elite Drop Taxi, best outstation cabs service and the rapid growing Intercity cab service provider in south india. Elite Drop Taxi is an online cab booking services for the customer who need call taxi quickly for Outstation travels. We are the experts in offering One Way drop and Round trip cab service for Outstation travels and also offering Hourly Packages for travel in allo ver south india.

</p>
                   </div>
                   <div className='flex flex-col gap-y-2 items-center justify-center md:flex-row lg:flex-row mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+919342426541 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 9342426541</span>
                              </a>
                         </div>
                      </div>

            </div>
        </div>
    )
}

export default About