import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FaCar } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";

import { FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules';
import bg from "../../assets/images/taxi_bg_1.jpg";
import bg1 from "../../assets/images/pattern_bg_5.jpg";
import madurai from "../../assets/places/maduraiscrool.jpg";
import trichy from "../../assets/places/trichyScroll.jpg";
import coimbatore from "../../assets/places/coimbatoreScroll.jpg";
import banglore from "../../assets/places/bangaloreScroll.jpg";
import salem from "../../assets/places/ps6.jpg";
import chennai from "../../assets/places/chennaiScroll.jpg"

const Vision = () => {
  const divStyle2={
    backgroundImage: `url( ${bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat:"no-repeat"

  }

  return (
    <div className='flex items-center justify-center flex-col p-5 gap-y-3 md:p-16 lg:p-20 ' id="service">
    <h4 className=' text-lg font-medium primary-color text-center'>OUR SERVICES</h4>

<h1 className="text-center font-bold text-5xl ">OUR SERVICES</h1>
<div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-2">
<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={trichy} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'> Trichy To Madurai </div>
<p>Reach Trichy, the city of Rock Fort temples, with ease and comfort through our professional taxi services.</p>
<div className='flex flex-col gap-y-2 items-center justify-center md:flex-row lg:flex-row mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+919342426541 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} />+91 9342426541</span>
                              </a>
                         </div>
                      </div>

</div>

{/* <div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={salem} alt="Madurai" className='hover:scale-110 transition ease-in w-full h-[120px]'/></div>
<div className='text-red-600 font-semibold text-xl'>Madurai to Rameswaram</div>
<p>Planning a pilgrimage? Our one-way taxi service to Rameswaram ensures a peaceful and convenient journey.</p>
</div> */}


<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={chennai} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'> Chennai To Madurai</div>
<p>Travel comfortably to the metropolitan hub of Chennai with our reliable taxi services.</p>
<div className='flex flex-col gap-y-2 items-center justify-center md:flex-row lg:flex-row mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+919342426541 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 9342426541</span>
                              </a>
                         </div>
                      </div>

</div>


<div className="grid grid-cols-1 bg-white px-5 gap-3">
<div><img src={coimbatore} alt="Madurai" className='hover:scale-110 transition ease-in w-full'/></div>
<div className='text-red-600 font-semibold text-xl'> Coimbatore To Madurai</div>
<p>Enjoy a hassle-free ride from the cultural capital of Tamil Nadu to the bustling city of Coimbatore.</p>
<div className='flex flex-col gap-y-2 items-center justify-center md:flex-row lg:flex-row mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+919342426541 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 9342426541</span>
                              </a>
                         </div>
                      </div>

</div>

</div>

{/* Navigation buttons */}
{/* <div className="swiper-button-prev"></div>
<div className="swiper-button-next"></div> */}
</div>

  )
}

export default Vision
