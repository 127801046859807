import React from "react";
import banner from "../assets/images/backgroundTaxi.jpg";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";



import { Link } from 'react-router-dom';
import headimg from '../assets/images/headerbackimg.png';



const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat:"no-repeat"
    // animation :`( ${animate} ) 1s linear infinite`  ,  
    
  };

  return (
    <>
    <div className=" form_bg relative head_bg mt-24 md:mt-12"  style={divStyle} >


    <div className="flex justify-center mt-2">
    <a href="tel:+919342426541 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} />+91 93424 26541</span>
                              </a>

      </div>

    <div className="relative bg-fixed flex justify-center  px-3 ">
        <div className='bg-[#1c1e32] w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form />
        </div>
      </div>
          {/* <div className="order-0 md:order-1 lg:order-1">
            <img src={banner}/>
            </div> */}
            <img src={headimg} className="absolute w-full object-fit:cover top-[85px] lg:w-[80%] right-[1px] "/>
            {/* <img src={carCard} className="absolute w-full object-fit:cover top-[85px] lg:w-[55%] right-[2px] "/> */}

        </div>
  

    {/* <div>
    <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Drop Cabs
            </p>
            <p>OneWay Drop</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Outstation Cabs
            </p>
            <p>Outstation Oneway,Round Trip</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Round Trip
            </p>
            <p>Local & Outstation</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Tour Packages
            </p>
            <p>Tamilnadu,Kerala,Andhra,Pondicherry,Telangana</p>
          </div>
        </div>
      </div>

    </div> */}
    </>

  );
};

export default BookingForm;
